.currencyInputStyle {
  width: 20em;
  height: 3.8em;
  border-radius: 5px;
  border: 0.5px solid #bdb9b9;
}

.currencyInputStyle:hover {
  border: 0.5px solid black;
}

.currencyInputStyle:focus {
  border: 1px solid #303f9f;
}
