/* Estilos para la barra de scroll en navegadores basados en WebKit */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
/* Estilos para la barra de scroll en navegadores que soporten la especificación CSS Scrollbars Level 1 */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
}

/* Estilos para la barra de scroll personalizada */
.scroll-wrapper {
  overflow-x: scroll;
  scrollbar-width: none;
}

.scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  position: relative;
  width: 100%;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
