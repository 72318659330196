/* Estilos generales para el modal de vista previa */
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.8); /* Fondo negro semitransparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Estilos para la barra superior del modal */
.image-preview-header {
  background: rgba(1, 2, 3, 0.4);
  color: #fff;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

/* Estilos para el contenido de la tabla */
.excel-preview-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/* Estilos para la tabla */
.excel-preview-content table {
  width: 100%;
  border-collapse: collapse;
}

.excel-preview-content th,
.excel-preview-content td {
  border: 1px solid #ddd;
  padding: 8px;
}

.excel-preview-content th {
  background-color: #f4f4f4;
}

.excel-preview-content tr:nth-child(even) {
  background-color: #f9f9f9;
}

.excel-preview-content tr:hover {
  background-color: #f1f1f1;
}

/* Estilos para el fondo oscuro al hacer zoom */
.image-preview-zoom-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fondo negro semitransparente */
  z-index: 9998;
}

/* Estilos para el mensaje de descarga */
.download-message {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7); /* Fondo negro semitransparente */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: none;
}

/* Estilos para el mensaje de descarga visible */
.download-message.active {
  display: block;
}
